/* eslint-disable dot-notation */
import React, { useEffect, useState } from "react";
import {
    FACEBOOK_SPECIAL_REQUIREMENTS_IOS,
    FB_PROCEED_TO_LOGIN,
} from "../../analytics/eventConstants";
import { trackEvent } from "../../analytics/sdkAnalytics";
import Background from "../../components/Background/Background";
import Loader from "../../components/Loader/Loader";
import ScreenNavbar from "../../components/ScreenNavbar/ScreenNavbar";
import {
    FACEBOOK_WORKPLATFORM_ID,
    FACEBOOK_WORKPLATFORM_NAME,
    PHYLLO_EXIT_REASON_EXIT_FROM_PLATFORM,
} from "../../constants/constants";
import { SDKInstanceState } from "../../context/SdkInstanceContext";
import { SDKParamsState } from "../../context/SdkParmasContext";
import proceed_login_fb from "../../assets/images/proceed_login_fb.svg";
import custom_info_icon from "../../assets/images/custom_info_icon.svg";
import "./FacebookIOSAuthScreen.scss";
import { isEmpty } from "../../utils/sdk_utils";
import { LoaderState } from "../../context/LoaderContext";
import { facebookIOSLocalization } from "./translations";
import { useGetRedirectAuthFlow } from "../../utils/CustomHooks/useGetRedirectAuthFlow";
import { WorkPlatformDetailsState } from "../../context/WorkPlatformDetailsContext";
import { LoaderWithStatusState } from "../../context/LoaderWithStatusContext";
import LoaderWithStatus from "../../components/LoaderWithStatus/LoaderWithStatus";
import { ConfigState } from "../../context/ConfigContext";

function FacebookIOSAuthScreen() {
    const { sdkInstance } = SDKInstanceState();
    const { sdkParams } = SDKParamsState();
    const { loader, dispatchLoaderContext } = LoaderState();
    const { configuration } = ConfigState();
    const [loaderMsgConfig, setLoaderMsgConfig] = useState(configuration.screens.short_loader_screen);
    const { isConnectLoader } = LoaderWithStatusState();
    const { workplatformDetails } = WorkPlatformDetailsState();
    const redirectAuthFlow = useGetRedirectAuthFlow(FACEBOOK_WORKPLATFORM_ID, FACEBOOK_WORKPLATFORM_NAME, sdkParams, workplatformDetails);

    useEffect(() => {
        dispatchLoaderContext(false);
    }, [sdkInstance]);

    if (loader) {
        if (isConnectLoader && !isEmpty(loaderMsgConfig)) {
            return (
                <LoaderWithStatus
                    message={loaderMsgConfig.title.replace("{{PlatformName}}", FACEBOOK_WORKPLATFORM_NAME)}
                    platform={FACEBOOK_WORKPLATFORM_NAME}
                />
            );
        }
        return <Loader />;
    }

    const infoText = [
        facebookIOSLocalization[sdkParams.language]["Facebook’s recent security update may trigger a CAPTCHA if login details are entered too quickly, such as with autofill."],
        facebookIOSLocalization[sdkParams.language]["This could interrupt the login process."],
        facebookIOSLocalization[sdkParams.language]["To ensure a smooth connection, manually enter your details or click inside the fields before submitting."]
    ];

    return (
        <>
            <Background />
            <div className="row col-lg-3 col-md-10 col-sm-10 col-12 m-auto  white-screen">
                <ScreenNavbar
                    className=""
                    backURL={-1}
                    exitReason={PHYLLO_EXIT_REASON_EXIT_FROM_PLATFORM + FACEBOOK_WORKPLATFORM_NAME}
                    screenName={FACEBOOK_SPECIAL_REQUIREMENTS_IOS}
                    workplatformDetails={{
                        workplatformId: FACEBOOK_WORKPLATFORM_ID,
                        workplatformName: FACEBOOK_WORKPLATFORM_NAME,
                    }}
                />
                <div className="scrollable-content-info-insta">
                    <div className="col-12  text-center ms-0 ">
                        <img className="custom-info-icon-img" src={custom_info_icon} alt="" />
                        <h2 className="heading-text-h2-insta">
                            {facebookIOSLocalization[sdkParams.language]["Important tip for connecting your Facebook account"]}
                        </h2>
                    </div>

                    <div className="main-div-insta mt-3">
                        <div className="question-info-container-ios">
                            <ul>
                                {infoText.map((sentence, index) => (
                                    <li key={index}>{sentence}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-intro-div-ios">
                    <div className="col-12 m-auto text-center mb-4">
                            <img
                             className="primary-btn-img"
                            onClick={() => {
                                trackEvent(FB_PROCEED_TO_LOGIN, {
                                    work_platform_id: FACEBOOK_WORKPLATFORM_ID,
                                    work_platform_name: FACEBOOK_WORKPLATFORM_NAME,
                                    account_connection_type: "New_Connection",
                                });
                                redirectAuthFlow()
                            }}
                                src={proceed_login_fb}
                                alt="Proceed to Facebook Login"
                            />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FacebookIOSAuthScreen;
