export const facebookLocalization = {
    en: {
        "Connecting your account requires a Facebook business page": "Connecting your account requires a Facebook business page",
        "Do you have a Facebook business page?": "Do you have a Facebook business page?",
        Yes: "Yes",
        No: "No",
        "Facebook has the above requirement for a successful account connection":
            "Facebook has the above requirement for a successful account connection.",
        "How to create a Facebook business page?": "How to create a Facebook business page?",
    },
    id: {
        "Connecting your account requires a Facebook business page": "Menghubungkan akun Anda memerlukan halaman bisnis Facebook",
        "Do you have a Facebook business page?": "Apakah Anda memiliki halaman bisnis Facebook?",
        Yes: "Ya",
        No: "Tidak",
        "Facebook has the above requirement for a successful account connection": "Facebook memiliki persyaratan di atas agar koneksi akun berhasil",
        "How to create a Facebook business page?": "Bagaimana cara membuat halaman bisnis Facebook?",
    },
    ja: {
        "Connecting your account requires a Facebook business page": "アカウントを接続するには Facebook ビジネス ページが必要です",
        "Do you have a Facebook business page?": "Facebookのビジネスページはありますか？",
        Yes: "はい",
        No: "いいえ",
        "Facebook has the above requirement for a successful account connection":
            "Facebook では、アカウント接続を成功させるために上記の要件があります",
        "How to create a Facebook business page?": "Facebookのビジネスページを作成するにはどうすればよいですか?",
    },
    cn: {
        "Connecting your account requires a Facebook business page": "连接您的帐户需要 Facebook 业务页面",
        "Do you have a Facebook business page?": "您有 Facebook 企业页面吗？",
        Yes: "是",
        No: "否",
        "Facebook has the above requirement for a successful account connection": "Facebook 对于成功的帐户连接有上述要求",
        "How to create a Facebook business page?": "如何创建 Facebook 企业页面？",
    },
    zh: {
        "Connecting your account requires a Facebook business page": "连接您的帐户需要 Facebook 业务页面",
        "Do you have a Facebook business page?": "您有 Facebook 企业页面吗？",
        Yes: "是",
        No: "否",
        "Facebook has the above requirement for a successful account connection": "Facebook 对于成功的帐户连接有上述要求",
        "How to create a Facebook business page?": "如何创建 Facebook 企业页面？",
    },
    fr: {
        "Connecting your account requires a Facebook business page": "La connexion de votre compte nécessite une page professionnelle Facebook",
        "Do you have a Facebook business page?": "Avez-vous une page professionnelle Facebook?",
        Yes: "Oui",
        No: "Non",
        "Facebook has the above requirement for a successful account connection":
            "Facebook a les exigences ci-dessus pour une connexion de compte réussie",
        "How to create a Facebook business page?": "Comment créer une page entreprise Facebook ?",
    },
    "zh-tw": {
        "Connecting your account requires a Facebook business page": "連接您的帳戶需要 Facebook 商業頁面",
        "Do you have a Facebook business page?": "您有 Facebook 企業專頁嗎？",
        Yes: "是的",
        No: "不",
        "Facebook has the above requirement for a successful account connection": "Facebook 對於成功的帳號連結有上述要求",
        "How to create a Facebook business page?": "如何建立 Facebook 企業專頁？",
    },
    es: {
        "Connecting your account requires a Facebook business page": "Conectar su cuenta requiere una página comercial de Facebook",
        "Do you have a Facebook business page?": "¿Tienes una página comercial en Facebook?",
        Yes: "Sí",
        No: "No",
        "Facebook has the above requirement for a successful account connection":
            "Facebook tiene el requisito anterior para una conexión de cuenta exitosa",
        "How to create a Facebook business page?": "¿Cómo crear una página empresarial en Facebook?",
    },
    pt: {
        "Connecting your account requires a Facebook business page": "Conectar sua conta requer uma página comercial do Facebook",
        "Do you have a Facebook business page?": "Você tem uma página comercial no Facebook?",
        Yes: "Sim",
        No: "Não",
        "Facebook has the above requirement for a successful account connection":
            "O Facebook tem o requisito acima para uma conexão de conta bem-sucedida",
        "How to create a Facebook business page?": "Como criar uma página comercial no Facebook?",
    },
};


export const facebookIOSLocalization = {
    en: {
        "Important tip for connecting your Facebook account": "Important tip for connecting your Facebook account",
        "Facebook’s recent security update may trigger a CAPTCHA if login details are entered too quickly, such as with autofill.": "Facebook’s recent security update may trigger a CAPTCHA if login details are entered too quickly, such as with autofill.",
        "This could interrupt the login process.": "This could interrupt the login process.",
        "To ensure a smooth connection, manually enter your details or click inside the fields before submitting.": "To ensure a smooth connection, manually enter your details or click inside the fields before submitting.",
    },
    id: {
        "Important tip for connecting your Facebook account": "Tips penting untuk menghubungkan akun Facebook Anda",
        "Facebook’s recent security update may trigger a CAPTCHA if login details are entered too quickly, such as with autofill.": "Pembaruan keamanan terbaru dari Facebook dapat memicu CAPTCHA jika detail login dimasukkan terlalu cepat, seperti menggunakan autofill.",
        "This could interrupt the login process.": "Hal ini dapat mengganggu proses login.",
        "To ensure a smooth connection, manually enter your details or click inside the fields before submitting.": "Untuk memastikan koneksi berjalan lancar, masukkan detail secara manual atau klik di dalam kolom sebelum mengirimkan.",
    },
    ja: {
        "Important tip for connecting your Facebook account": "Facebookアカウントを接続する際の重要なヒント",
        "Facebook’s recent security update may trigger a CAPTCHA if login details are entered too quickly, such as with autofill.": "Facebookの最近のセキュリティ更新により、オートフィルのようにログイン情報をすばやく入力するとCAPTCHAが発生する場合があります。",
        "This could interrupt the login process.": "これにより、ログインプロセスが中断される可能性があります。",
        "To ensure a smooth connection, manually enter your details or click inside the fields before submitting.": "スムーズに接続するために、手動で詳細を入力するか、送信する前にフィールド内をクリックしてください。",
    },
    cn: {
        "Important tip for connecting your Facebook account": "连接您的 Facebook 帐户的重要提示",
        "Facebook’s recent security update may trigger a CAPTCHA if login details are entered too quickly, such as with autofill.": "Facebook 最近的安全更新可能会在快速输入登录信息时（例如使用自动填充）触发 CAPTCHA。",
        "This could interrupt the login process.": "这可能会中断登录过程。",
        "To ensure a smooth connection, manually enter your details or click inside the fields before submitting.": "为确保顺利连接，请手动输入信息或在提交前点击输入框内。",
    },
    zh: {
        "Important tip for connecting your Facebook account": "连接您的 Facebook 帐户的重要提示",
        "Facebook’s recent security update may trigger a CAPTCHA if login details are entered too quickly, such as with autofill.": "Facebook 最近的安全更新可能会在快速输入登录信息时（例如使用自动填充）触发 CAPTCHA。",
        "This could interrupt the login process.": "这可能会中断登录过程。",
        "To ensure a smooth connection, manually enter your details or click inside the fields before submitting.": "为确保顺利连接，请手动输入信息或在提交前点击输入框内。",
    },
    "zh- tw": {
        "Important tip for connecting your Facebook account": "連接您的 Facebook 帳戶的重要提示",
        "Facebook’s recent security update may trigger a CAPTCHA if login details are entered too quickly, such as with autofill.": "Facebook 最近的安全更新可能會在快速輸入登入資訊時（例如使用自動填寫）觸發 CAPTCHA。",
        "This could interrupt the login process.": "這可能會中斷登入流程。",
        "To ensure a smooth connection, manually enter your details or click inside the fields before submitting.": "為確保順利連接，請手動輸入資訊或在提交前點擊輸入框內。",
    },
    fr: {
        "Important tip for connecting your Facebook account": "Conseil important pour connecter votre compte Facebook",
        "Facebook’s recent security update may trigger a CAPTCHA if login details are entered too quickly, such as with autofill.": "La récente mise à jour de sécurité de Facebook peut déclencher un CAPTCHA si les informations de connexion sont saisies trop rapidement, comme avec le remplissage automatique.",
        "This could interrupt the login process.": "Cela peut interrompre le processus de connexion.",
        "To ensure a smooth connection, manually enter your details or click inside the fields before submitting.": "Pour assurer une connexion fluide, saisissez vos informations manuellement ou cliquez dans les champs avant de soumettre.",
    },
    es: {
        "Important tip for connecting your Facebook account": "Consejo importante para conectar tu cuenta de Facebook",
        "Facebook’s recent security update may trigger a CAPTCHA if login details are entered too quickly, such as with autofill.": "La reciente actualización de seguridad de Facebook puede activar un CAPTCHA si los datos de inicio de sesión se ingresan demasiado rápido, como al usar el autocompletado.",
        "This could interrupt the login process.": "Esto podría interrumpir el proceso de inicio de sesión.",
        "To ensure a smooth connection, manually enter your details or click inside the fields before submitting.": "Para asegurar una conexión sin problemas, ingresa tus datos manualmente o haz clic dentro de los campos antes de enviar.",
    },
    pt: {
        "Important tip for connecting your Facebook account": "Dica importante para conectar sua conta do Facebook",
        "Facebook’s recent security update may trigger a CAPTCHA if login details are entered too quickly, such as with autofill.": "A recente atualização de segurança do Facebook pode acionar um CAPTCHA se as informações de login forem inseridas muito rapidamente, como com o preenchimento automático.",
        "This could interrupt the login process.": "Isso pode interromper o processo de login.",
        "To ensure a smooth connection, manually enter your details or click inside the fields before submitting.": "Para garantir uma conexão tranquila, insira suas informações manualmente ou clique nos campos antes de enviar.",
    }
}